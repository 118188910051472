import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import img from "../../images/addpointment-home.png";
import { Col, Row } from "../layout/Grid";
import * as styles from "./ProductWebsite.module.scss";

const ProductWebsite = () => {
  const [enter, setEnter] = useState(false);
  const inputRef = useRef();

  const scrollHandler = () => {
    if (inputRef.current.getBoundingClientRect().top <= 10) {
      setEnter(true);
    }
    if (inputRef.current.getBoundingClientRect().bottom <= 10) {
      setEnter(false);
    }
  };

  useEffect(() => {
    typeof window !== "undefined" &&
      window.addEventListener("scroll", scrollHandler, true);
    return () => {
      typeof window !== "undefined" &&
        window.removeEventListener("scroll", scrollHandler, true);
    };
  }, []);
  return (
    <section ref={inputRef}>
      <div className="section wrapper">
        <Row className={classNames(enter ? styles.entered : null)}>
          <Col width="30%">
            <div className="h4">
              APPT lets you focus on providing excellent service to your
              customers while we take care of your daily operations.
            </div>
          </Col>
          <Col width="65%">
            <div className={styles["screenshot"]}>
              <img src={img} alt="" />
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default ProductWebsite;
