import React from "react";
import * as styles from "./ProductWebBanner.module.scss";
import img from "../../images/homeBanner.svg";
import ScrollReveal from "../layout/ScrollReveal";
import { externalURL } from "../../config/url";

const ProductWebBanner = () => {
  return (
    <section className={styles.container}>
      <ScrollReveal effect="fade">
        <div className={styles.background}></div>
      </ScrollReveal>
      <div className={`section wrapper ${styles.main}`}>
        <ScrollReveal>
          <h3 className="h2 text-center">
            Manage your entire Business Process
          </h3>
        </ScrollReveal>
        <ScrollReveal>
          <div className={`text-center ${styles.desc}`}>
            <p>
              APPT has everything you need to run your business, schedule
              bookings, engage with customers, accept payment, and stay in
              control of your business - all from one app.
            </p>
            <a
              href={externalURL.apptDemo}
              target="_blank"
              rel="noreferrer"
              className="btn"
            >
              Book a Demo
            </a>
          </div>
        </ScrollReveal>
        <ScrollReveal delay={-100}>
          <img src={img} alt="" className={styles.img} />
        </ScrollReveal>
      </div>
    </section>
  );
};

export default ProductWebBanner;
